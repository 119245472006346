import {React, useEffect} from "react"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const formUrl = "https://docs.google.com/forms/d/e/1FAIpQLScS6aRLRKceKv6x0BBpkrvCJaXj_5eHEzDjQdFC5nk6ae-_8g/viewform?usp=sf_link";
const brandUrl = process.env.GATSBY_SITE_URL;

const assetVersion = "?v=2112161951";

export default () => {
  useEffect(() => {
    navigate('https://hotaru-personalized.com');
  }, []);
  return null;
}

const AboutSerumPage = () => (
    <Layout sectionClasses={"t-kuroki-project"} dataNamespace={"kuroki-project"} isHeader={false}>
      <header className="t-kuroki-project__header">
        <div className="mw-guide">
          <Link to={brandUrl}><img src={"/images/common/c-logo-tac--white.svg"} /></Link>
          <div className="t-kuroki-project__header__nav">
            <Link to={"#concept"} className="js-anchor nav-oneway" data-anchor-id="concept" data-anchor-gap="-80">CONCEPT</Link>
            <Link to={"#schedule"} className="js-anchor nav-oneway" data-anchor-id="schedule" data-anchor-gap="-80">SCHEDULE</Link>
            <Link to={"#project"} className="js-anchor nav-oneway" data-anchor-id="project" data-anchor-gap="-80">PROJECT</Link>
          </div>
        </div>
      </header>
      <SEO title="New Color Project" image={"https://hotaru-personalized.com/images/meta/ogp210728.png"}/>
      <div className="t-kuroki-project">
        <section className="t-kuroki-project__mv">
          <picture className="t-kuroki-project__mv__bg">
            <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/mask-group.png" + assetVersion} />
            <img src={"/images/specialcontents/kuroki-project/pc/mv-bg.png" + assetVersion} alt="" />
          </picture>
          <div className="t-kuroki-project__inner">
            <div className="t-kuroki-project__inner__ttl">
              <h2 className="t-kuroki-project__inner__ttl__sub">HOTARU PERSONALIZED</h2>
              <h1 className="t-kuroki-project__inner__ttl__main">NEW COLOR PROJECT</h1>
              <p className="t-kuroki-project__inner__ttl__thx">
                受付終了いたしました。<br />
                たくさんのご応募ありがとうございました！
              </p>
            </div>
            <div className="t-kuroki-project__inner__concept" id="concept">
              <h2>CONCEPT</h2>
              <p className="t-kuroki-project__inner__concept__guide">
                HOTARU PERSONALIZEDは、オンラインを通じて一人ひとりの中に眠る美しさを引き出すパーソナライズスキンケアブランドです。<br />
                <br />
                美しさに正解はなく、それぞれの肌に答えがあるはず。もっとパーソナライズの可能性を広げたい。1人ひとりの「美しさ=Color」をもっと引き出したい。<br />
                <br />
                Chief Personalize Officerに就任した女優・黒木瞳さんとのコラボレーションを皮切りに、パーソナライズスキンケアの可能性を追求するプロジェクトを開始します。<br />
                <br />
                あなたもHOTARU PERSONALIZEDの新商品開発を行うプロジェクトメンバーの一員になりませんか？皆様からのご応募をお待ちしています。
              </p>
            </div>
          </div>
        </section>

        <section className="t-kuroki-project__concept">
          <picture className="t-kuroki-project__concept__top">
            <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/message-01.jpg" + assetVersion} />
            <img src={"/images/specialcontents/kuroki-project/pc/message-01.jpg" + assetVersion} />
          </picture>
          <div className="t-kuroki-project__concept__left">
            <h3>HOTARU PERSONALIZED</h3>
            <h3>CPO / Chief personalized <br className="pc-hidden" />officer</h3>
            <h2>黒木 瞳</h2>
            <p>
              初めて「HOTARU PERSONALIZED」を知ったとき、一人ひとりの肌を診断してその人に合うスキンケアアイテムをお届けしてくださるというのが、これからの時代にとても合っているのではないかと感じました。<br />
              <br />
              スキンケアアイテムは数多くあり、どれを選べば良いのかとたくさんの方が迷っていらっしゃると思います。<br />
              若い方も年配の方も。そんなみなさんに安心感と信頼感のあるスキンケアアイテムをご提供するとともに、「HOTARU PERSONALIZED」を通して“パーソナライズの可能性”を多くの方に広めていきたいと考えています。
            </p>
          </div>
          <div className="t-kuroki-project__concept__right">
            <picture >
              <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/message-02.jpg" + assetVersion} />
              <img src={"/images/specialcontents/kuroki-project/pc/message-02.jpg" + assetVersion} />
            </picture>
          </div>
          <picture className="t-kuroki-project__concept__bottom">
            <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/message-03.jpg" + assetVersion} />
            <img src={"/images/specialcontents/kuroki-project/pc/message-03.jpg" + assetVersion} />
          </picture>
        </section>
        <section className="t-kuroki-project__schedule" id="schedule">
          <h2>SCHEDULE</h2>
          <p>スケジュール</p>
          <picture className="t-kuroki-project__schedule__chart">
            <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/schedule-text.png" + assetVersion} />
            <img src={"/images/specialcontents/kuroki-project/pc/schedule-text.png" + assetVersion} />
          </picture>
          <picture className="t-kuroki-project__schedule__bottom">
            <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/schedule-ky.jpg" + assetVersion} />
            <img src={"/images/specialcontents/kuroki-project/pc/schedule-ky.jpg" + assetVersion} />
          </picture>
        </section>
        <section className="t-kuroki-project__project" id="project">
          <h2>PROJECT</h2>
          <p>プロジェクト</p>
          <div className="t-kuroki-project__project__one">
            <div className="cat">
              <img className="t-kuroki-project__project__one__num" src={"/images/specialcontents/kuroki-project/pc/project-01.png" + assetVersion} />
              <h3>PRODUCT</h3>
            </div>
            <picture className="t-kuroki-project__project__one__img">
              <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/project-01-img.jpg" + assetVersion} />
              <img src={"/images/specialcontents/kuroki-project/pc/project-01-img.jpg" + assetVersion} />
            </picture>
            <p>
              開発中の試作品を体験いただき、アンケートに回答していただきます。<br />
              皆様からいただいた意見をもとに、試作品をさらに進化させていきます。
            </p>
          </div>
          <div className="t-kuroki-project__project__two">
            <div className="cat">
              <img className="t-kuroki-project__project__two__num" src={"/images/specialcontents/kuroki-project/pc/project-02.png" + assetVersion} />
              <h3>EVENT</h3>
            </div>
            <picture className="t-kuroki-project__concept__two__img">
              <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/project-02-img.jpg" + assetVersion} />
              <img src={"/images/specialcontents/kuroki-project/pc/project-02-img.jpg" + assetVersion} />
            </picture>
            <div className="bg-white">
              <p>
                商品開発担当者とプロジェクト参加者による座談会を開催します。進化した試作品について、ご意見をいただきます。
              </p>
              <p className="caution">※黒木瞳さんのイベント参加については未定となっております。</p>
            </div>
          </div>
          <div className="t-kuroki-project__project__three">
            <div className="cat">
              <img className="t-kuroki-project__project__three__num" src={"/images/specialcontents/kuroki-project/pc/project-03.png" + assetVersion} />
              <h3>EXPERIENCE</h3>
            </div>
            <picture className="t-kuroki-project__concept__three__img">
              <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/project-03-img.jpg" + assetVersion} />
              <img src={"/images/specialcontents/kuroki-project/pc/project-03-img.jpg" + assetVersion} />
            </picture>
            <div className="bg-white">
              <p>
                プロジェクト期間中は、HOTARU PERSONALIZED ローション＆モイスチャライザーの定期コースを無料でお申し込みいただけます。<br />
                また、実際に完成した新商品を先行でお使いいただけます。
              </p>
            </div>
          </div>
        </section>
        <section className="t-kuroki-project__entry">
          <picture className="t-kuroki-project__entry__bg">
            <source media="(max-width:959px)" srcSet={"/images/specialcontents/kuroki-project/sp/entry-bg.png" + assetVersion} />
            <img src={"/images/specialcontents/kuroki-project/pc/entry-bg.jpg" + assetVersion} />
          </picture>
          <div className="t-kuroki-project__entry__desc">
            <h2>ENTRY</h2>
            <p>エントリー方法</p>
            <div className="t-kuroki-project__entry__desc__block blank-m-top">
              <h3>応募締切</h3>
              <p className="fin">受付終了いたしました。<br />たくさんのご応募<br className="pc-hidden" />ありがとうございました！</p>
              <h3>応募資格</h3>
              <p>美容・スキンケアに興味のある方</p>
              <p className="caution">※性別、職業不問</p>
              <h3>募集人数</h3>
              <p>8名まで</p>
            </div>
          </div>
        </section>
        <section className="t-kuroki-project__contact">
          <h3>本件に関するお問い合わせ先</h3>
          <p>
            株式会社Sparty<br />
            東京都渋谷区恵比寿1-19-19 恵比寿<br className="pc-hidden" />ビジネスタワー4階<br />
            E-MAIL: hotaru@shop.sparty.jp
          </p>
        </section>
        <section className="t-kuroki-project__overview">
          <p>
            <span className="bold">プロジェクト参加メンバー募集要項</span><br />
            <br />
            エントリーフォームから、必要事項を明記の上送信ボタンをクリック。<br />
            <br />
            ■プロジェクトタイトル<br />
            「HOTARU PERSONALIZED NEW COLOR PROJECT」<br />
            <br />
            ■プロジェクト期間<br />
            2021年12月16日(木)  ～ 2022年9月30日(金) ※予定<br />
            <br />
            ■応募方法
          </p>
          <ol>
            <li>特設サイトより、フォームへアクセス</li>
            <li>必要事項 (氏名・メールアドレス・お住まいの地域・応募理由) をご記載いただき、利用規約に同意の上、入力内容確認をクリック</li>
            <li>応募完了</li>
          </ol>
          <p>
            <br />
            ■当選者の発表<br />
            応募者の中から厳正なる選考の上、選考結果をご連絡いたします。<br />
            ※選考結果のご連絡は2022年2月21日を予定しております。<br />
            <br />
            ■ご注意
          </p>
          <ul>
            <li>本プロジェクトへはパソコン / スマートフォンからご応募ください。<br />
              ※ご利用の環境によってはご応募できない可能性がございます。<br />
              ※インターネット通信料・接続等の費用はすべてお客さまの負担になります。
            </li>
            <li>重複してご応募された場合は、１回分のご応募のみ有効になります。</li>
            <li>本応募に際して、製品のご購入は必要ございません。</li>
            <li>選考結果のご連絡時期は、諸事情により遅れる場合がございます。</li>
            <li>当選後、ご都合により本プロジェクトへの参加が難しくなった場合、いつでも辞退いただくことは可能です。</li>
            <li>本プロジェクトで開催するイベントなどで写真撮影をさせていただく予定です。イベントの様子を撮影した写真を弊社サイトなどで使用させていただく可能性がございます。</li>
            <li>応募者のSNSへの投稿内容などにより第三者との間でトラブルが発生した場合は、当社はいかなる責任も負いかねます。</li>
            <li>公序良俗に反する行為、法令に違反する行為又はそれらの恐れのある行為は禁止です。</li>
            <li>選考結果についてのお問い合わせには一切お答えできませんのであらかじめご了承ください。</li>
          </ul>
          <p>
            <br />
            ■個人情報について<br />
            本プロジェクトのご応募に際し、入力いただきましたお客さまの個人情報 (郵便番号、住所、氏名、電話番号、メールアドレス等) につきましては、以下の目的で利用させていただきます。<br />
          </p>
          <ul>
            <li>本件の当選 / 落選のご連絡</li>
            <li>イベントご案内・試作品発送等、本プロジェクトに関連したご連絡</li>
            <li>マーケティング等、個人を特定しない統計的情報の形の利用</li>
          </ul>
          <p>
            その他お客さまの個人情報のお取り扱いについては、プライバシーポリシーをご覧ください<br />
            <br />
            プライバシポリシーは<Link to="https://store.medulla.co.jp/privacy?_ebx=1oar14p3x6.1631589801.7j2fleq">こちら</Link>
          </p>
        </section>
      </div>
    </Layout>
)

// export default AboutSerumPage
